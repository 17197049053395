/*
 * Global javascript
 */

import '../img/index';

// JavaScript modules
require('jquery');
require('bootstrap');
require('socket.io-client');
require('tablesorter');

// Fontawesome
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

library.add(far, fas, fab)
dom.watch()

// Expose items to window
window.jQuery = window.$ = jQuery;
window.Tether = require('tether');
window.slider = require('./bootstrap-slider.js');
window.io = io;
window.Popper = require('popper.js');
window.ClipboardJS = require('clipboard');
// Your own code
require('./plugins.js');
window.notify = require('./bs-notify.js');