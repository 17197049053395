function formatDateStr(date) {
    date = new Date(date);
    var retTime = "";
    retTime += date.toLocaleDateString() + ' ';
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    retTime += hours + ':' + minutes + ' ' + ampm;
    return retTime;
}

$(document).ready(function() {
    /* Animate scroll to element # */
    $("a.btn-scroll").on('click', function(event) {
        if (this.hash !== "") {
            event.preventDefault();

            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 70
            }, 800, function() {
                window.location.hash = hash;
            });
        }
    });
    /* Copy to clipboard buttons */
    new ClipboardJS('.copy-clipboard');
    /* Scroll to top button */
    var offset = 200;
    var duration = 300;

    $(window).scroll(function() {
        if ($(this).scrollTop() > offset) {
            $(".back-to-top").fadeIn(duration);
        } else {
            $(".back-to-top").fadeOut(duration);
        }
    });

    $(".back-to-top").click(function(event) {
        $("html, body").animate({
            scrollTop: 0
        }, duration);
    });

    // Locale format server-side UTC dates
    $('.jsDate').each(function() {
        $(this).html(formatDateStr($(this).html()));
    });
});

$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})

/*
// Emoji Picker
//First construct an instance of EmojiPicker

//Next tell it where to listen for a click, the container it should be appended to, and the input/textarea/contenteditable it needs to work with
const icon      = document.getElementById('emoji-trigger');
const container = document.getElementById('chatCard');
const editable  = document.getElementById('messageText');
 
picker.listenOn(icon, container, editable);
*/

function ackAlert(alert_id) {
    var url = $('#globalData').data('ack-alert-url')
    url = url.replace('alert_id', alert_id);
    var csrf = $('#globalData').data('csrf-token');
    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", csrf);
            }
        }
    })
    $.ajax({
        type: "POST",
        url: url,
        timeout: 100000
    });  
    return;
}
window.ackAlert = ackAlert;

function htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
window.htmlDecode = htmlDecode;

function createBannerAlert(id, subject, message) {
    var alertHtml = '<div id="alert_' + id + '" class="alert bg-primary text-center p-3" role="alert">' +
    '<button type="button" class="close text-dark" data-dismiss="alert" aria-label="Close" onclick="ackAlert(' + id + ')">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' +
    '<h4 class="alert-heading mb-0 font-weight-bold">' + subject + '</h4>' +
    '<p class="m-0 text-dark">' + message + '</p>' +
  '</div>';
  $('#main').prepend(alertHtml);
}
window.createBannerAlert = createBannerAlert;

var rtAlertSocket = io.connect(location.protocol + '//' + document.domain + ':' + location.port + '/rt_alert',
{
'reconnection': true,
'reconnectionDelay': 1000,
'reconnectionDelayMax' : 5000,
'reconnectionAttempts': 5 });

rtAlertSocket.on('json', function(message) {
    createBannerAlert(message.alert_id, message.subject, message.message);
});